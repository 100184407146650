<template>
    <LayoutNext>
        <template #page-title>
            Pex Collection Asset View
        </template>
        <nav aria-label="breadcrumb" style="font-size: 13px;">
            <MDBBreadcrumb>
                <MDBBreadcrumbItem><router-link to="/pex-collections">Pex Collection</router-link>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem><a @click.prevent="goBack" class="text-primary" style="cursor: default;">Pex
                        Collection
                        Asset</a>
                </MDBBreadcrumbItem>
                <MDBBreadcrumbItem active style="cursor: default;">
                    Pex Collection Asset View
                </MDBBreadcrumbItem>
            </MDBBreadcrumb>
        </nav>
        <MDBCard class="shadow-0 card-container">
            <MDBCardHeader class="py-3 px-0">
                <template v-if="isLoading">
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="image-container d-flex justify-content-center align-items-center">
                            <div class="loader"></div>
                            <img src="@/assets/cs-loader.png" alt="Loading image">
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="header-container">
                        <MDBCardTitle class="my-auto fw-bold" tag="h5" :title="assetInfo.title">{{ assetInfo.title }}
                        </MDBCardTitle>
                        <div class="actions">
                            <!--<MDBDropdown v-model="dropdown">
                      <MDBDropdownToggle
                        outline="tertiary"
                        @click.stop.prevent="dropdown = !dropdown"
                        >Action
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem href="#">Action 1 </MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                    <MDBBtn outline="tertiary"> Copies </MDBBtn>-->
                            <MDBBtn class="fw-bold" color="primary" size="sm" type="button" @click="toggleAssetStatus">
                                {{
                                    toggleAssetStatusText }}
                            </MDBBtn>
                        </div>
                    </div>
                </template>
            </MDBCardHeader>
            <MDBCardBody class="px-0 py-3">
                <template v-if="isLoading">
                    <div class="d-flex justify-content-center align-items-center mb-4">
                        <div class="image-container d-flex justify-content-center align-items-center">
                            <div class="loader"></div>
                            <img src="@/assets/cs-loader.png" alt="Loading image">
                        </div>
                    </div>
                </template>
                <template v-else>
                    <MDBAlert class="m-auto mb-4" color="warning" static v-if="!assetInfo.isTracked">
                        <i class="fas fa-exclamation-triangle me-3"></i>
                        You're not tracking this asset. Turn on tracking to start discovering
                        copies.
                    </MDBAlert>
                    <div class="align-items-start d-block gap-4">
                        <div class="mb-4 rounded-5">
                            <MDBCard>
                                <MDBCardBody>
                                    <!-- <img :src="assetInfo.thumbnailUrl" class="img-fluid" alt="thumbnailUrl" /> -->
                                    <div class="d-md-flex d-block gap-4">
                                        <MDBCol class="d-flex justify-content-center align-items-center">
                                            <MDBCardImg class="rounded mb-3 mb-md-0" top :src="assetInfo.thumbnailUrl"
                                                alt="thumbnailUrl" />
                                        </MDBCol>
                                        <MDBCol>
                                            <div class="info-container ps-2">
                                                <MDBCardTitle class="fw-bold mb-3" style="font-size: 17px">Source
                                                    Information</MDBCardTitle>
                                                <MDBCardText class="info">
                                                    <span>Platform</span>
                                                    <span>Pexoso</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>Production Date</span>
                                                    <span>{{ parseDateLong(assetInfo.productionDate) }}</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>Duration</span>
                                                    <span>{{ secondsToTime(assetInfo.duration) }}</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>Media Type</span>
                                                    <span>{{ assetInfo.mediaType }}</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>Music Video</span>
                                                    <span>No</span>
                                                </MDBCardText>
                                            </div>
                                        </MDBCol>
                                        <MDBCol>
                                            <div class="info-container ps-2">
                                                <MDBCardTitle class="fw-bold mb-3" style="font-size: 17px">Basic
                                                    Information</MDBCardTitle>
                                                <MDBCardText class="info">
                                                    <span>Date Added</span>
                                                    <span>{{ parseDateLong(assetInfo.createdAt) }}</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>Last Edited</span>
                                                    <span>{{ parseDateLong(assetInfo.updatedAt) }}</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>Tracked From</span>
                                                    <span>{{ parseDateLong(assetInfo.trackedFrom) }}</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>Added By</span>
                                                    <span>—</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>Collections</span>
                                                    <span>{{ assetInfo.collections.length }}</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>Reference ID</span>
                                                    <span>—</span>
                                                </MDBCardText>
                                            </div>
                                        </MDBCol>
                                        <MDBCol>
                                            <div class="info-container ps-2">
                                                <MDBCardTitle class="fw-bold mb-3" style="font-size: 17px">Audio
                                                    Recording Information</MDBCardTitle>
                                                <MDBCardText class="info">
                                                    <span>Album</span>
                                                    <span>{{
                                                        assetInfo.music.album ? assetInfo.music.album : "—"
                                                        }}</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>Artist</span>
                                                    <span>{{
                                                        assetInfo.music.artist ? assetInfo.music.artist : "—"
                                                        }}</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>ISRC</span>
                                                    <span>{{
                                                        assetInfo.music.isrcs.length != 0
                                                            ? assetInfo.music.isrcs.length
                                                            : "—"
                                                    }}</span>
                                                </MDBCardText>
                                                <MDBCardText class="info">
                                                    <span>ISWC</span>
                                                    <span>{{
                                                        assetInfo.music.iswcs.length != 0
                                                            ? assetInfo.music.iswcs.length
                                                            : "—"
                                                    }}</span>
                                                </MDBCardText>
                                            </div>
                                        </MDBCol>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                        <div class="d-md-flex d-block gap-4">
                            <MDBCard class="statistic-card">
                                <MDBCardHeader style="font-size: 18px;">Asset Statistics</MDBCardHeader>
                                <MDBCardBody>
                                    <div>
                                        <h6>Unable to display statistics.</h6>
                                        <p>Statistics for this Asset cannot currently be
                                            displayed.</p>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                            <MDBCard class="statistic-card">
                                <MDBCardHeader style="font-size: 18px;">Asset History</MDBCardHeader>
                                <MDBCardBody>
                                    <p>Nothing to display.</p>
                                </MDBCardBody>
                            </MDBCard>
                        </div>
                    </div>
                </template>
            </MDBCardBody>
        </MDBCard>
        <MDBToast v-model="toast" :delay="2000" autohide position="top-right" appendToBody stacking width="350px"
            color="success" text="white" icon="fas fa-check fa-lg me-2">
            <template #title> Success </template>
            Status Changed Successfully
        </MDBToast>
    </LayoutNext>
</template>

<script setup>
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardHeader, MDBCardText, MDBBtn, MDBAlert, MDBToast, MDBBreadcrumb, MDBBreadcrumbItem, MDBCardImg, MDBCol } from "mdb-vue-ui-kit";
import LayoutNext from "@/views/v3/LayoutNext.vue";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { GetAsset } from "@/services/Assets/GetAsset";
import { parseDateLong } from "@/helpers/parseDate";
import { PutAssetTrackingStatus } from "@/services/Assets/PutAssetTrackingStatus";
import { useTitle } from "@vueuse/core";

const assetInfo = ref({});
const isLoading = ref(true);
const route = useRoute();

onMounted(async () => {
    assetInfo.value = await GetAsset(route.params.assetId);
    useTitle(`${assetInfo.value.title} | CreatorShield`);
    isLoading.value = false;
});

const secondsToTime = (e) => {
    const h = Math.floor(e / 3600)
        .toString()
        .padStart(2, "0"),
        m = Math.floor((e % 3600) / 60)
            .toString()
            .padStart(2, "0"),
        s = Math.floor(e % 60)
            .toString()
            .padStart(2, "0");

    return `${h}:${m}:${s}`;
};

// const dropdown = ref(false);
const toggleAssetStatus = async () => {
    isLoading.value = true;
    await PutAssetTrackingStatus(assetInfo.value.assetIdString, {
        IsTracked: !assetInfo.value.isTracked,
    });
    toast.value = true;
    assetInfo.value = {
        ...assetInfo.value,
        isTracked: !assetInfo.value.isTracked,
    };
    isLoading.value = false;
};

const toggleAssetStatusText = computed(() => {
    return assetInfo.value.isTracked ? "Untrack this asset" : "Track this asset";
});

const toast = ref(false);

const router = useRouter();
const goBack = () => {
    router.go(-1);
};
</script>

<style scoped>
.btn-primary {
    background-color: var(--primary);
}

.statistic-card {
    margin-bottom: 0.75rem;
    width: 100%;
}

.statistic-card .card-header {
    font-weight: bold;
    font-size: 20px;
}

.statistic-card .card-body {
    height: 200px;
    display: grid;
    place-items: center;
}

.info-container {
    padding: 10px 20px;
}

.info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto 0;
    margin-bottom: 5px;
}

.header-container {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.header-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actions {
    display: flex;
}

.actions .solid {
    background-color: var(--primary);
    border-color: var(--primary);
    color: white;
}

.actions .solid:hover {
    background-color: var(--accent);
    border-color: var(--accent);
}

span {
    font-size: 14px;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
